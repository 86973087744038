import { AxiosError } from "axios";
import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";
import { push } from "react-router-redux";

import { CompanyManagementService } from "modules/company-management/services";
import {
  addCompanyError,
  addCompanySuccess,
  createPlatformError,
  createPlatformSuccess,
  deleteCompanyAIModelError,
  deleteCompanyAIModelSuccess,
  deleteUserFromCompanyError,
  deleteUserFromCompanySuccess,
  disableDomainError,
  disableDomainSuccess,
  duplicateCompanyError,
  duplicateCompanySuccess,
  editCompanyError,
  editCompanyLimitsError,
  editCompanyLimitsSuccess,
  editCompanyModulesError,
  editCompanyModulesSuccess,
  editCompanySettingError,
  editCompanySettingSuccess,
  editCompanySuccess,
  editPlatformError,
  editPlatformSuccess,
  editSingleCompanyLimitsError,
  editSingleCompanyLimitsSuccess,
  enableDomainError,
  enableDomainSuccess,
  fetchBotsError,
  fetchBotsSuccess,
  fetchChurnTypesError,
  fetchChurnTypesSuccess,
  fetchClearbitError,
  fetchClearbitSuccess,
  fetchCompaniesError,
  fetchCompaniesSuccess,
  fetchCompaniesToSwitchError,
  fetchCompaniesToSwitchSuccess,
  fetchCompanyAdminLogsError,
  fetchCompanyAdminLogsSuccess,
  fetchCurrenciesError,
  fetchCurrenciesSuccess,
  fetchIndustriesError,
  fetchIndustriesSuccess,
  fetchIndustrySubClassesError,
  fetchIndustrySubClassesSuccess,
  fetchPlatformsError,
  fetchPlatformsSuccess,
  fetchSalesPeopleError,
  fetchSalesPeopleSuccess,
  fetchSingleCompanyError,
  fetchSingleCompanySuccess,
  getAiModelsError,
  getAiModelsSuccess,
  getCompanyAIModelsError,
  getCompanyAIModelsSuccess,
  getCurrentLimitsError,
  getCurrentLimitsSuccess,
  getSMByTokenError,
  getSMCompanyByTokenSuccess,
  getSMCompanyConnectionSuccess,
  populateHistoricalLeadsError,
  populateHistoricalLeadsSuccess,
  postCompanyAIModelError,
  postCompanyAIModelSuccess,
  removeCompanyError,
  removeCompanySuccess,
  removePlatformError,
  removePlatformSuccess,
  resendActivationError,
  resendActivationSuccess,
  saveBotError,
  saveBotSuccess,
  sendOldContractError,
  sendOldContractSuccess,
  setLimitError,
  setLimitSuccess,
  syncCompanyWithPlanhatError,
  syncCompanyWithPlanhatSuccess,
  syncCompanyWithYouniumError,
  syncCompanyWithYouniumSuccess,
  toggleCompanyActivatedError,
  toggleCompanyActivatedSuccess,
} from "modules/company-management/store/actions/actionCreators";
import * as actionTypes from "modules/company-management/store/actions/actionTypes";
import { Company, ModuleKeys } from "libs/models";
import { SagaPayload } from "libs/utils/types";
import { ConnectCompanies } from "modules/company-management/store/actions/actionTypes";
import { Error } from "modules/free-users/store/actions/actionTypes";

const companyManagementService = CompanyManagementService.getInstance();

export function* fetchCompaniesWorker({
  payload,
}: actionTypes.FetchCompanies): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.fetchCompanies,
      payload?.params
    );

    yield put(fetchCompaniesSuccess(data));
  } catch (e) {
    yield put(fetchCompaniesError((e as AxiosError).response?.data as Error));
  }
}

export function* fetchCompaniesAdminLogWorker({
  payload,
}: actionTypes.FetchAdminLogs): SagaIterator {
  try {
    const response = yield call(
      companyManagementService.fetchAdminLogs as any,
      payload?.params,
      Number(payload?.companyId)
    );

    const { data } = response.data;

    yield put(fetchCompanyAdminLogsSuccess(data));

    if (
      data.meta.totalPages > Number(data.meta.currentPage) &&
      payload.params?.page
    ) {
      yield put(
        push(
          `/company/view/${payload?.companyId}/logs?page=${payload?.params?.page}`
        )
      );
    }
  } catch (e) {
    yield put(
      fetchCompanyAdminLogsError((e as AxiosError).response?.data as Error)
    );
  }
}

export function* fetchSingleCompanyWorker({
  payload,
}: actionTypes.FetchSingleCompany): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.fetchSingleCompany,
      payload!.companyId
    );
    yield put(fetchSingleCompanySuccess({ company: data }));
  } catch (e) {
    if ((e as AxiosError).response?.status === 404) {
      yield put(push("/company"));
    }

    yield put(
      fetchSingleCompanyError((e as AxiosError).response?.data as Error)
    );
  }
}

export function* addCompanyWorker({
  payload,
}: actionTypes.AddCompany): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.addCompany,
      payload!.data.company
    );

    yield put(addCompanySuccess({ insertId: data.data.insertId }));
    yield put(push(`/company/edit/${data.data.insertId}`));
  } catch (e) {
    yield put(addCompanyError((e as AxiosError).response?.data as Error));
  }
}

export function* deleteUserFromCompanyWorker({
  payload,
}: actionTypes.DeleteUserFromCompany): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.deleteUserFromCompany,
      payload
    );

    yield put(deleteUserFromCompanySuccess(data));
  } catch (e) {
    yield put(
      deleteUserFromCompanyError((e as AxiosError).response?.data as Error)
    );
  }
}

export function* editCompanyWorker({
  payload,
}: actionTypes.EditCompany): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.editCompany,
      payload!.data.company
    );
    yield put(editCompanySuccess(data.data));
  } catch (e) {
    yield put(
      editCompanyError(
        ((e as AxiosError).response?.data as any).exception.response.message
      )
    );
  }
}

export function* editCompanyModulesWorker({
  payload,
}: actionTypes.EditCompanyModules): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.editCompanyModules,
      payload!.data
    );
    yield put(
      editCompanyModulesSuccess({
        data: {
          companyId: data.id,
          key: payload!.data.key,
          value: payload!.data.value,
          currentCompany: payload!.data.currentCompany,
        },
      })
    );
  } catch (e) {
    yield put(
      editCompanyModulesError((e as AxiosError).response?.data as Error)
    );
  }
}

export function* editCompanyLimitsWorker({
  payload,
}: actionTypes.EditCompanyLimits): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.editCompanyLimits,
      payload!.data
    );

    yield put(
      editCompanyLimitsSuccess({
        data: {
          companyId: data.data.company_id,
          limits: payload!.data.limits,
        },
      })
    );
  } catch (e) {
    yield put(
      editCompanyLimitsError((e as AxiosError).response?.data as Error)
    );
  }
}

export function* editSingleCompanyLimitsWorker({
  payload,
}: actionTypes.EditSingleCompanyLimits): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.editCompanyLimits,
      payload
    );
    yield put(editSingleCompanyLimitsSuccess(data));
  } catch (e) {
    yield put(
      editSingleCompanyLimitsError((e as AxiosError).response?.data as Error)
    );
  }
}

export function* getCurrentLimitsWorker({
  payload,
}: actionTypes.GetCurrentLimits): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.getCurrentLimits,
      payload
    );
    yield put(getCurrentLimitsSuccess(data.data));
  } catch (e) {
    yield put(getCurrentLimitsError((e as AxiosError).response?.data as Error));
  }
}

export function* editCompanySettingWorker({
  payload,
}: actionTypes.EditCompanySetting): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.editCompanySetting,
      payload
    );
    yield put(editCompanySettingSuccess(data));
  } catch (e) {
    yield put(
      editCompanySettingError((e as AxiosError).response?.data as Error)
    );
  }
}

export function* removeCompanyWorker({
  payload,
}: actionTypes.RemoveCompany): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.removeCompany,
      payload!.companyId
    );

    yield put(removeCompanySuccess({ companyId: payload!.companyId }));
    yield put(push("/company"));
  } catch (e) {
    yield put(removeCompanyError((e as AxiosError).response?.data as Error));
  }
}

export function* sendOldContractWorker({
  payload,
}: actionTypes.SendOldContract): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.sendOldContract,
      payload
    );
    yield put(sendOldContractSuccess(data));
  } catch (e) {
    yield put(sendOldContractError((e as AxiosError).response?.data as Error));
  }
}

export function* syncCompanyWithPlanhatWorker({
  payload,
}: actionTypes.SyncCompanyWithPlanhat): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.syncCompanyWithPlanhat,
      payload
    );
    const { data: companyData } = yield call(
      companyManagementService.fetchSingleCompany,
      Number(payload)
    );
    yield put(
      syncCompanyWithPlanhatSuccess({
        message: data.message,
        company: companyData,
      })
    );
  } catch (e) {
    yield put(
      syncCompanyWithPlanhatError((e as AxiosError).response?.data as Error)
    );
  }
}

export function* syncCompanyWithYouniumWorker({
  payload,
}: actionTypes.SyncCompanyWithYounium): SagaIterator {
  try {
    const { data }: SagaPayload<{ message: string }> = yield call(
      companyManagementService.syncCompanyWithYounium,
      payload
    );
    const { data: companyData }: SagaPayload<Company> = yield call(
      companyManagementService.fetchSingleCompany,
      Number(payload)
    );
    yield put(
      syncCompanyWithYouniumSuccess({
        message: data.message,
        company: companyData,
      })
    );
  } catch (e) {
    yield put(
      syncCompanyWithYouniumError((e as AxiosError).response?.data as Error)
    );
  }
}

export function* fetchCurrenciesWorker(): SagaIterator {
  try {
    const { data } = yield call(companyManagementService.fetchCurrencies);
    yield put(fetchCurrenciesSuccess(data));
  } catch (e) {
    yield put(fetchCurrenciesError((e as AxiosError).response?.data as Error));
  }
}

export function* fetchChurnTypesWorker(): SagaIterator {
  try {
    const { data } = yield call(companyManagementService.fetchChurnTypes);
    yield put(fetchChurnTypesSuccess(data));
  } catch (e) {
    yield put(fetchChurnTypesError((e as AxiosError).response?.data as Error));
  }
}

export function* fetchClearbitWorker({
  payload,
}: actionTypes.FetchClearbit): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.fetchClearbit,
      payload!.companyId
    );
    yield put(fetchClearbitSuccess(data));
  } catch (e) {
    yield put(
      fetchClearbitError(
        (e as AxiosError).response?.data as Error,
        (e as AxiosError).response?.status || null
      )
    );
  }
}

export function* enableDomainWorker({
  payload,
}: actionTypes.EnableDomain): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.enableDomain,
      payload!.data
    );
    yield put(enableDomainSuccess(data));
  } catch (e) {
    yield put(enableDomainError((e as AxiosError).response?.data as Error));
  }
}

export function* setLimit({ payload }: actionTypes.SetLimit): SagaIterator {
  try {
    yield call(companyManagementService.setLimit, payload!.data);
    yield put(setLimitSuccess(payload.data));
  } catch (e) {
    yield put(setLimitError((e as AxiosError).response?.data as Error));
  }
}

export function* disableDomainWorker({
  payload,
}: actionTypes.DisableDomain): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.disableDomain,
      payload!.data.companyId
    );
    yield put(disableDomainSuccess(data));
  } catch (e) {
    yield put(disableDomainError((e as AxiosError).response?.data as Error));
  }
}

export function* fetchBotsWorker({
  payload,
}: actionTypes.FetchBots): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.fetchBots,
      payload!.companyId
    );
    yield put(fetchBotsSuccess(data.data));
  } catch (e) {
    yield put(fetchBotsError((e as AxiosError).response?.data as Error));
  }
}

export function* saveBotWorker({ payload }: actionTypes.SaveBot): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.saveBot,
      payload!.data
    );
    yield put(saveBotSuccess(data));
  } catch (e) {
    yield put(saveBotError((e as AxiosError).response?.data as Error));
  }
}

export function* fetchSalesPeopleWorker(): SagaIterator {
  try {
    const { data } = yield call(companyManagementService.fetchSalesPeople);
    yield put(fetchSalesPeopleSuccess(data));
  } catch (e) {
    yield put(fetchSalesPeopleError((e as AxiosError).response?.data as Error));
  }
}

export function* fetchIndustries(): SagaIterator {
  try {
    const { data } = yield call(companyManagementService.fetchIndustries);
    yield put(fetchIndustriesSuccess(data));
  } catch (e) {
    yield put(fetchIndustriesError((e as AxiosError).response?.data as Error));
  }
}

export function* fetchIndustrySubClasses(): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.fetchIndustrySubClasses
    );
    yield put(fetchIndustrySubClassesSuccess(data));
  } catch (e) {
    yield put(
      fetchIndustrySubClassesError((e as AxiosError).response?.data as Error)
    );
  }
}

export function* switchToCompanyWorker({
  payload,
}: actionTypes.FetchCompaniesToSwitch): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.switchToCompany,
      payload!.companyId
    );
    yield put(fetchCompaniesToSwitchSuccess(data));
  } catch (e) {
    yield put(
      fetchCompaniesToSwitchError((e as AxiosError).response?.data as Error)
    );
  }
}

export function* duplicateCompanyWorker({
  payload,
}: actionTypes.DuplicateWithCompany): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.duplicateCompany,
      payload.data,
      payload.companyId
    );
    yield put(duplicateCompanySuccess(data));
  } catch (e) {
    yield put(
      duplicateCompanyError(((e as AxiosError).response?.data as any).exception)
    );
  }
}

export function* resendActivationWorker({
  payload,
}: actionTypes.ResendActivation): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.resendActivation,
      payload!.companyId
    );
    yield put(resendActivationSuccess(data));
  } catch (e) {
    yield put(resendActivationError((e as AxiosError).response?.data as Error));
  }
}

export function* toggleCompanyActivatedWorker({
  payload,
}: actionTypes.ToggleCompanyActivated): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.toggleCompanyActivated,
      { id: payload!.id, churn: payload?.churn }
    );
    yield put(
      toggleCompanyActivatedSuccess({ ...data.data, churn: payload.churn })
    );
  } catch (e) {
    yield put(
      toggleCompanyActivatedError((e as AxiosError).response?.data as Error)
    );
  }
}

export function* fetchPlatformsWorker({
  payload,
}: actionTypes.FetchPlatforms): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.fetchPlatforms,
      payload.companyId
    );
    yield put(fetchPlatformsSuccess(data));
  } catch (e) {
    yield put(fetchPlatformsError((e as AxiosError).response?.data as Error));
  }
}

export function* createPlatformWorker({
  payload,
}: actionTypes.CreatePlatform): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.createPlatform,
      payload
    );
    yield put(createPlatformSuccess(data));
  } catch (e) {
    yield put(createPlatformError((e as AxiosError).response?.data as Error));
  }
}

export function* editPlatformWorker({
  payload,
}: actionTypes.EditPlatform): SagaIterator {
  try {
    const { data } = yield call(companyManagementService.editPlatform, payload);
    yield put(editPlatformSuccess(data));
  } catch (e) {
    yield put(editPlatformError((e as AxiosError).response?.data as Error));
  }
}

export function* removePlatformWorker({
  payload,
}: actionTypes.RemovePlatform): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.removePlatform,
      payload.platformId
    );
    yield put(removePlatformSuccess(data));
  } catch (e) {
    yield put(removePlatformError((e as AxiosError).response?.data as Error));
  }
}

export function* populateHistoricalLeadsWorker({
  payload,
}: actionTypes.PopulateHistoricalLeads): SagaIterator {
  try {
    const leadsSuccess = yield call(
      companyManagementService.populateHistoricalLeads,
      payload
    );
    const editSuccess = yield call(
      companyManagementService.editCompanyModules,
      {
        companyId: Number(payload.companyId),
        value: 1,
        key: ModuleKeys.is_lead_stage,
      }
    );

    yield put(populateHistoricalLeadsSuccess(leadsSuccess.data.message));
    yield put(
      editCompanyModulesSuccess({
        data: {
          companyId: editSuccess.data.id,
          value: 1,
          key: ModuleKeys.is_lead_stage,
        },
      })
    );
  } catch (e) {
    yield put(
      populateHistoricalLeadsError((e as AxiosError).response?.data as Error)
    );
  }
}

export function* getAiModelsWorker(): SagaIterator {
  try {
    const { data } = yield call(companyManagementService.getAIModels);
    yield put(getAiModelsSuccess(data.models.chat));
  } catch (error) {
    yield put(getAiModelsError(error));
  }
}

export function* getCompanyAiModels({
  payload,
}: actionTypes.GetCompanyAiModels): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.getCompanyAIModels,
      payload
    );
    yield put(
      getCompanyAIModelsSuccess(
        data.map((model: { model_name: string }) => model.model_name)
      )
    );
  } catch (error) {
    yield put(getCompanyAIModelsError(error));
  }
}

export function* postAiModelsWorker({
  payload,
}: actionTypes.PostCompanyAiModels): SagaIterator {
  try {
    yield call(
      companyManagementService.postCompanyAIModel,
      payload.companyID,
      payload.model
    );
    yield put(postCompanyAIModelSuccess(payload.model));
  } catch (error) {
    yield put(postCompanyAIModelError(error));
  }
}

export function* deleteAiModelWorker({ payload }: any): SagaIterator {
  try {
    yield call(
      companyManagementService.deleteCompanyAIModel,
      payload.companyID,
      payload.model
    );
    yield put(deleteCompanyAIModelSuccess(payload.model));
  } catch (error) {
    yield put(deleteCompanyAIModelError(error));
  }
}

export function* companyConnectionSaga({
  payload,
}: ConnectCompanies): SagaIterator {
  try {
    yield call(
      companyManagementService.connectSMCompany,
      payload.companyID,
      payload.internalToken,
      payload.sm_name,
      payload.leadoo_name
    );

    payload.onConnectionSuccess();
  } catch (error) {
    yield put(getSMByTokenError(error.response.data.error));
  }
}

export function* getSMCompanyConnectionSaga({
  payload,
}: actionTypes.GetSMConnection): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.getSMCompanyConnection,
      payload.companyID
    );
    yield put(getSMCompanyConnectionSuccess(payload.companyID, data.smCompany));
  } catch (error) {
    yield put(getSMByTokenError(error.response.data.error));
  }
}

export function* getSMCompanyByToken({
  payload,
}: actionTypes.GetSmCompanyByToken): SagaIterator {
  try {
    const { data } = yield call(
      companyManagementService.getSMCompanyData,
      payload.companyID,
      payload.token
    );
    yield put(getSMCompanyByTokenSuccess(data.internalToken, data.smCompany));

    payload.onSuccess();
  } catch (error) {
    yield put(getSMByTokenError(error.response.data.error));
  }
}

export function* deleteSmCompanyConnection({
  payload,
}: actionTypes.DeleteSmConnection): SagaIterator {
  try {
    yield call(
      companyManagementService.deleteSmCompanyConnection,
      payload.companyID,
      payload.name
    );

    payload.onDisconnect();
  } catch (error) {
    yield put(getSMByTokenError(error.response.data.error));
  }
}
