import { SagaIterator } from "redux-saga";
import { put, call } from "redux-saga/effects";
import { push } from "react-router-redux";

import { AuthService } from "modules/auth/services";
import {
  signOutSuccess,
  signOutError,
  fetchMeOAuthSuccess,
  fetchMeOAuthError,
  fetchMeUserSuccess,
  fetchMeUserError,
  fetchUserByCodeSuccess,
  fetchUserByCodeError,
} from "modules/auth/store/actions/actionCreators";
import {
  FetchUserByCode,
} from "modules/auth/store/actions/actionTypes";

const authService = AuthService.getInstance();

export function* signOutWorker(): SagaIterator {
  try {
    const res = yield call(authService.logout);
    if (res) {
      yield put(signOutSuccess());
    }
  } catch (e: any) {
    yield put(signOutError((e as any).response.data.errors));
  }
}

export function* fetchMeOAuthWorker(): SagaIterator {
  try {
    const { data } = yield call(authService.fetchMe);
    yield put(fetchMeOAuthSuccess(data.oauth));
  } catch (e: any) {
    yield put(fetchMeOAuthError((e as any).response.data.errors));
  }
}

export function* fetchMeUserWorker(): SagaIterator {
  try {
    const { data } = yield call(authService.fetchMe);
    const redirectUrl = window.sessionStorage.getItem("redirectUrl");
    window.sessionStorage.removeItem("redirectUrl");
    yield put(fetchMeUserSuccess(data.user));
    if (redirectUrl) {
      yield put(push(redirectUrl));
    }
  } catch (e: any) {
    window.sessionStorage.setItem("redirectUrl", window.location.pathname);
    yield put(fetchMeUserError(e.response.data.errors));
  }
}

export function* fetchUserByCodeWorker({
  payload,
}: FetchUserByCode): SagaIterator {
  try {
    const { data } = yield call(
      authService.fetchUserByCode,
      payload!.code,
      payload!.pkceVerifier
    );

    yield put(fetchUserByCodeSuccess(data));
    yield put(push("/company"));
  } catch (e: any) {
    yield put(fetchUserByCodeError(e.response.data.errors));
  }
}
