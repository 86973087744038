import { AxiosResponse } from "axios";

import { BaseService } from "libs/services";

export class AuthService extends BaseService {
  private ADMIN_API_ROUTE = this.ADMIN_API_HOST;

  static _instance: AuthService;

  updateTimeout: null | number = null;

  constructor() {
    super();

    if (AuthService._instance) {
      throw Error("Unable to create new instance");
    }
  }

  static getInstance(): AuthService {
    if (!AuthService._instance) {
      AuthService._instance = new AuthService();
    }

    return AuthService._instance;
  }

  static getAuthState(): boolean {
    return !!localStorage.getItem("jwt");
  }

  static getRefreshState(): boolean {
    return !!localStorage.getItem("refreshToken");
  }

  public logout = async (): Promise<boolean> => {
    try {
      await this.deleteSession();
      return true;
    } catch (e) {
      return false;
    } finally {
      window.localStorage.removeItem("jwt");
      window.localStorage.removeItem("refreshToken");

      window.location.reload();
    }
  };

  public deleteSession = (): Promise<AxiosResponse> => this.delete(`${this.ADMIN_API_ROUTE}/auth/logout`);

  public fetchMe = (): Promise<AxiosResponse> => this.get(`${this.ADMIN_API_ROUTE}/auth/user`);
  public fetchLoginUrl = (): Promise<AxiosResponse> => this.get(`${this.ADMIN_API_ROUTE}/auth/url`);

  public fetchUserByCode = async (code: string, pkceVerifier: string | null): Promise<AxiosResponse | null> => {
    try {
      const res = await this.get(`${this.ADMIN_API_ROUTE}/auth/user-by-code/${code}?pkceVerifier=${pkceVerifier}`);

      localStorage.setItem("jwt", res.data.accessToken);
      localStorage.setItem("refreshToken", res.data.refreshToken);
      localStorage.removeItem("pkceVerifier");

      return res;
    } catch (e) {
      localStorage.removeItem("jwt");
      localStorage.removeItem("refreshToken");
      window.location.href = "/";
      localStorage.removeItem("pkceVerifier");
      return null;
    }
  };

  public refreshToken = async (refresh_token: string): Promise<AxiosResponse | void> => {
    try {
      const res = await this.get(`${this.ADMIN_API_ROUTE}/auth/refresh-token?refreshToken=${refresh_token}`);

      localStorage.setItem("jwt", res.data.accessToken);
      localStorage.setItem("refreshToken", res.data.refreshToken);
      window.location.reload();

      return res;
    } catch (e) {
      this.logout().then(() => this.fetchLoginUrl().then(r => {
        localStorage.setItem("pkceVerifier", r.data.pkceVerifier);
        window.location.href = r.data.url;
      }));
    }
  };
}
