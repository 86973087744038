import {
  Bot,
  ChurnData,
  ClearbitData,
  Company,
  CompanyChurnData,
  Currency,
  HistoricalLeadsData,
  Limits,
  MediaPartner,
  PaginationMeta,
  User,
} from "libs/models";
import {
  QueryParams,
} from "modules/company-management/utils/types";
import {
  DuplicateCompanyData,
  Industry,
  IndustrySubClass,
  Links,
  Platform,
  SalesManagoCompanyInfo,
  SalesManagoConnectedCompany,
} from "modules/company-management/models";
import { SettingPayload } from "modules/company-management/components/common/CompanyManagement/CompanyView/SettingsList/interfaces";
import LogEntry from "modules/admin-logs/LogEntry";
import * as actionTypes from "./actionTypes";
import { Error, SwitchToCompanyData } from "./actionTypes";
import { CompanyManagementActionsTypes as c } from "./actions";

export const fetchCompanies = (
  params?: QueryParams
): actionTypes.FetchCompanies => ({
  type: c.FETCH_COMPANIES,
  payload: {
    params,
  },
});

export const fetchCompaniesSuccess = (payload: {
  items: Array<Company>;
  meta: PaginationMeta;
  links: Links;
}): actionTypes.FetchCompaniesSuccess => ({
  type: c.FETCH_COMPANIES_SUCCESS,
  payload,
});

export const fetchCompaniesError = (
  payload: Error
): actionTypes.FetchCompaniesError => ({
  type: c.FETCH_COMPANIES_ERROR,
  payload,
});

export const fetchCompanyAdminLogsSuccess = (payload: {
  items: Array<LogEntry>;
  meta: PaginationMeta;
}): actionTypes.FetchAdminLogsSuccess => ({
  type: c.FETCH_COMPANY_LOGS_SUCCESS,
  payload,
});

export const fetchCompanyAdminLogsError = (
  payload: Error
): actionTypes.FetchAdminLogsError => ({
  type: c.FETCH_COMPANY_LOGS_ERROR,
  payload,
});

export const fetchCompanyAdminLogs = (
  companyId: string,
  params?: QueryParams
): actionTypes.FetchAdminLogs => ({
  type: c.FETCH_COMPANY_LOGS,
  payload: {
    companyId: Number(companyId),
    params,
  },
});

export const fetchSingleCompany = (payload: {
  companyId: number;
}): actionTypes.FetchSingleCompany => ({
  type: c.FETCH_SINGLE_COMPANY,
  payload,
});

export const fetchSingleCompanySuccess = (payload: {
  company: Company;
}): actionTypes.FetchSingleCompanySuccess => ({
  type: c.FETCH_SINGLE_COMPANY_SUCCESS,
  payload,
});

export const fetchSingleCompanyError = (
  payload: Error
): actionTypes.FetchSingleCompanyError => ({
  type: c.FETCH_SINGLE_COMPANY_ERROR,
  payload,
});

export const addCompany = (company: Company): actionTypes.AddCompany => ({
  type: c.ADD_COMPANY,
  payload: {
    data: {
      company,
    },
  },
});

export const addCompanySuccess = (payload: {
  insertId: number;
}): actionTypes.AddCompanySuccess => ({
  type: c.ADD_COMPANY_SUCCESS,
  payload,
});

export const addCompanyError = (
  payload: Error
): actionTypes.AddCompanyError => ({
  type: c.ADD_COMPANY_ERROR,
  payload,
});

export const editCompanyModules = ({
  key,
  value,
  companyId,
  currentCompany,
}: {
  key: string;
  value: any;
  companyId: number;
  currentCompany?: boolean;
}): actionTypes.EditCompanyModules => ({
  type: c.EDIT_COMPANY_MODULES,
  payload: {
    data: {
      key,
      value,
      companyId,
      currentCompany,
    },
  },
});

export const editCompanyLimits = ({
  limits,
  companyId,
}: {
  limits: Limits;
  companyId: number;
}): actionTypes.EditCompanyLimits => ({
  type: c.EDIT_COMPANY_LIMITS,
  payload: {
    data: {
      limits,
      companyId,
    },
  },
});

export const editSingleCompanyLimits = (payload: {
  companyId: number;
  limits: Limits;
}): actionTypes.EditSingleCompanyLimits => ({
  type: c.EDIT_SINGLE_COMPANY_LIMITS,
  payload,
});

export const editCompanyModulesSuccess = (payload: {
  data: {
    companyId: number;
    key: string;
    value: any;
    currentCompany?: boolean;
  };
}): actionTypes.EditCompanyModulesSuccess => ({
  type: c.EDIT_COMPANY_MODULES_SUCCESS,
  payload,
});

export const editCompanyLimitsSuccess = (payload: {
  data: { companyId: number; limits: Limits };
}): actionTypes.EditCompanyLimitsSuccess => ({
  type: c.EDIT_COMPANY_LIMITS_SUCCESS,
  payload,
});

export const editSingleCompanyLimitsSuccess = (payload: {
  data: Limits;
}): actionTypes.EditSingleCompanyLimitsSuccess => ({
  type: c.EDIT_SINGLE_COMPANY_LIMITS_SUCCESS,
  payload,
});

export const editSingleCompanyLimitsError = (
  payload: Error
): actionTypes.EditSingleCompanyLimitsError => ({
  type: c.EDIT_SINGLE_COMPANY_LIMITS_ERROR,
  payload,
});

export const editCompanyModulesError = (
  payload: Error
): actionTypes.EditCompanyModulesError => ({
  type: c.EDIT_COMPANY_MODULES_ERROR,
  payload,
});

export const editCompanyLimitsError = (
  payload: Error
): actionTypes.EditCompanyLimitsError => ({
  type: c.EDIT_COMPANY_LIMITS_ERROR,
  payload,
});

export const editCompany = (company: Company): actionTypes.EditCompany => ({
  type: c.EDIT_COMPANY,
  payload: {
    data: {
      company,
    },
  },
  meta: {
    companyId: Number(company.id),
  },
});

export const editCompanySuccess = (payload: {
  company: Company;
}): actionTypes.EditCompanySuccess => ({
  type: c.EDIT_COMPANY_SUCCESS,
  payload,
});

export const editCompanyError = (
  payload: Error
): actionTypes.EditCompanyError => ({
  type: c.EDIT_COMPANY_ERROR,
  payload,
});

export const editCompanySetting = (payload: {
  companyId: number;
  setting: SettingPayload;
}): actionTypes.EditCompanySetting => ({
  type: c.EDIT_COMPANY_SETTING,
  payload,
});

export const editCompanySettingSuccess = (
  payload: SettingPayload
): actionTypes.EditCompanySettingSuccess => ({
  type: c.EDIT_COMPANY_SETTING_SUCCESS,
  payload,
});

export const editCompanySettingError = (
  payload: Error
): actionTypes.EditCompanySettingError => ({
  type: c.EDIT_COMPANY_SETTING_ERROR,
  payload,
});

export const removeCompany = (
  companyId: number
): actionTypes.RemoveCompany => ({
  type: c.REMOVE_COMPANY,
  payload: {
    companyId,
  },
});

export const removeCompanySuccess = (payload: {
  companyId: number;
}): actionTypes.RemoveCompanySuccess => ({
  type: c.REMOVE_COMPANY_SUCCESS,
  payload,
});

export const removeCompanyError = (
  payload: Error
): actionTypes.RemoveCompanyError => ({
  type: c.REMOVE_COMPANY_ERROR,
  payload,
});

export const resetCompanies = (): actionTypes.ShowCompaniesPage => ({
  type: c.SHOW_COMPANIES_PAGE,
});

export const sendOldContract = (payload: {
  companyId: number;
  email: string;
}): actionTypes.SendOldContract => ({
  type: c.SEND_OLD_CONTRACT,
  payload,
});

export const sendOldContractSuccess = (payload: {
  message: string;
}): actionTypes.SendOldContractSuccess => ({
  type: c.SEND_OLD_CONTRACT_SUCCESS,
  payload,
});

export const sendOldContractError = (
  payload: Error
): actionTypes.SendOldContractError => ({
  type: c.SEND_OLD_CONTRACT_ERROR,
  payload,
});

export const fetchCurrencies = (): actionTypes.FetchCurrencies => ({
  type: c.FETCH_CURRENCIES,
});

export const fetchCurrenciesSuccess = (
  payload: Array<Currency>
): actionTypes.FetchCurrenciesSuccess => ({
  type: c.FETCH_CURRENCIES_SUCCESS,
  payload,
});

export const fetchCurrenciesError = (
  payload: Error
): actionTypes.FetchCurrenciesError => ({
  type: c.FETCH_CURRENCIES_ERROR,
  payload,
});

export const fetchChurnTypes = (): actionTypes.FetchChurnTypes => ({
  type: c.FETCH_CHURN_TYPES,
});

export const fetchChurnTypesSuccess = (
  payload: Array<ChurnData>
): actionTypes.FetchChurnTypesSuccess => ({
  type: c.FETCH_CHURN_TYPES_SUCCESS,
  payload,
});

export const fetchChurnTypesError = (
  payload: Error
): actionTypes.FetchChurnTypesError => ({
  type: c.FETCH_CHURN_TYPES_ERROR,
  payload,
});

export const fetchClearbit = (
  companyId: number
): actionTypes.FetchClearbit => ({
  type: c.FETCH_CLEARBIT,
  payload: {
    companyId,
  },
});

export const fetchClearbitSuccess = (
  payload: ClearbitData
): actionTypes.FetchClearbitSuccess => ({
  type: c.FETCH_CLEARBIT_SUCCESS,
  payload,
});

export const fetchClearbitError = (
  error: Error,
  errorCode: number | null
): actionTypes.FetchClearbitError => ({
  type: c.FETCH_CLEARBIT_ERROR,
  payload: { error: error, errorCode: errorCode },
});

export const enableDomain = ({
  companyId,
  domain,
  limit,
}: {
  companyId: number;
  domain: string;
  limit: number;
}): actionTypes.EnableDomain => ({
  type: c.ENABLE_DOMAIN,
  payload: {
    data: {
      companyId,
      domain,
      limit,
    },
  },
});

export const setLimit = ({
  companyId,
  limit,
}: {
  companyId: number;
  limit: number;
}): actionTypes.SetLimit => ({
  type: c.SET_LIMIT,
  payload: {
    data: {
      companyId,
      limit,
    },
  },
});

export const setLimitSuccess = ({
  limit,
}: {
  limit: number;
}): actionTypes.SetLimitSuccess => ({
  type: c.SET_LIMIT_SUCCESS,
  payload: limit,
});

export const setLimitError = (payload: Error): actionTypes.SetLimitError => ({
  type: c.SET_LIMIT_ERROR,
  payload,
});

export const enableDomainSuccess = (payload: {
  message: string;
  clearbit: ClearbitData;
}): actionTypes.EnableDomainSuccess => ({
  type: c.ENABLE_DOMAIN_SUCCESS,
  payload,
});

export const enableDomainError = (
  payload: Error
): actionTypes.EnableDomainError => ({
  type: c.ENABLE_DOMAIN_ERROR,
  payload,
});

export const disableDomain = (
  companyId: number
): actionTypes.DisableDomain => ({
  type: c.DISABLE_DOMAIN,
  payload: {
    data: {
      companyId,
    },
  },
});

export const disableDomainSuccess = (
  payload: ClearbitData
): actionTypes.DisableDomainSuccess => ({
  type: c.DISABLE_DOMAIN_SUCCESS,
  payload,
});

export const disableDomainError = (
  payload: Error
): actionTypes.DisableDomainError => ({
  type: c.DISABLE_DOMAIN_ERROR,
  payload,
});

export const fetchBots = (companyId: number): actionTypes.FetchBots => ({
  type: c.FETCH_BOTS,
  payload: {
    companyId,
  },
});

export const fetchBotsSuccess = (payload: {
  bots: Array<Bot>;
  companies: Array<MediaPartner>;
  isMedia: boolean;
}): actionTypes.FetchBotsSuccess => ({
  type: c.FETCH_BOTS_SUCCESS,
  payload,
});

export const fetchBotsError = (payload: Error): actionTypes.FetchBotsError => ({
  type: c.FETCH_BOTS_ERROR,
  payload,
});

export const saveBot = (data: {
  media_company_id: number | null;
  code_media: string | null;
  bot_id: number;
  company_id: number;
}): actionTypes.SaveBot => ({
  type: c.SAVE_BOT,
  payload: {
    data,
  },
});

export const saveBotSuccess = (payload: {
  data: { insertId: number };
}): actionTypes.SaveBotSuccess => ({
  type: c.SAVE_BOT_SUCCESS,
  payload,
});

export const saveBotError = (payload: Error): actionTypes.SaveBotError => ({
  type: c.SAVE_BOT_ERROR,
  payload,
});

export const syncCompanyWithPlanhat = (
  payload: string
): actionTypes.SyncCompanyWithPlanhat => ({
  type: c.SYNC_COMPANY_WITH_PLANHAT,
  payload,
});

export const syncCompanyWithPlanhatError = (
  payload: Error
): actionTypes.SyncCompanyWithPlanhatError => ({
  type: c.SYNC_COMPANY_WITH_PLANHAT_ERROR,
  payload,
});

export const syncCompanyWithPlanhatSuccess = (payload: {
  message: string;
  company: Company;
}): actionTypes.SyncCompanyWithPlanhatSuccess => ({
  type: c.SYNC_COMPANY_WITH_PLANHAT_SUCCESS,
  payload,
});

export const syncCompanyWithYounium = (
  payload: string
): actionTypes.SyncCompanyWithYounium => ({
  type: c.SYNC_COMPANY_WITH_YOUNIUM,
  payload,
});

export const syncCompanyWithYouniumError = (
  payload: Error
): actionTypes.SyncCompanyWithYouniumError => ({
  type: c.SYNC_COMPANY_WITH_YOUNIUM_ERROR,
  payload,
});

export const syncCompanyWithYouniumSuccess = (payload: {
  message: string;
  company: Company;
}): actionTypes.SyncCompanyWithYouniumSuccess => ({
  type: c.SYNC_COMPANY_WITH_YOUNIUM_SUCCESS,
  payload,
});

export const fetchSalesPeople = (): actionTypes.FetchSalesPeople => ({
  type: c.FETCH_SALES_PEOPLE,
});

export const fetchSalesPeopleSuccess = (
  payload: Array<User>
): actionTypes.FetchSalesPeopleSuccess => ({
  type: c.FETCH_SALES_PEOPLE_SUCCESS,
  payload,
});

export const fetchSalesPeopleError = (
  payload: Error
): actionTypes.FetchSalesPeopleError => ({
  type: c.FETCH_SALES_PEOPLE_ERROR,
  payload,
});

export const fetchIndustries = (): actionTypes.FetchIndustries => ({
  type: c.FETCH_INDUSTRIES,
});

export const fetchIndustriesSuccess = (
  payload: Array<Industry>
): actionTypes.FetchIndustriesSuccess => ({
  type: c.FETCH_INDUSTRIES_SUCCESS,
  payload,
});

export const fetchIndustriesError = (
  payload: Error
): actionTypes.FetchIndustriesError => ({
  type: c.FETCH_INDUSTRIES_ERROR,
  payload,
});

export const fetchIndustrySubClasses =
  (): actionTypes.FetchIndustrySubClasses => ({
    type: c.FETCH_INDUSTRY_SUB_CLASSES,
  });

export const fetchIndustrySubClassesSuccess = (
  payload: Array<IndustrySubClass>
): actionTypes.FetchIndustrySubClassesSuccess => ({
  type: c.FETCH_INDUSTRY_SUB_CLASSES_SUCCESS,
  payload,
});

export const fetchIndustrySubClassesError = (
  payload: Error
): actionTypes.FetchIndustrySubClassesError => ({
  type: c.FETCH_INDUSTRY_SUB_CLASSES_ERROR,
  payload,
});

export const fetchCompaniesToSwitch = (
  companyId: number
): actionTypes.FetchCompaniesToSwitch => ({
  type: c.FETCH_COMPANIES_TO_SWITCH,
  payload: {
    companyId,
  },
});

export const duplicateCompany = (
  data: DuplicateCompanyData,
  companyId: number
): actionTypes.DuplicateWithCompany => ({
  type: c.DUPLICATE_COMPANY,
  payload: {
    companyId,
    data,
  },
});

export const resendActivation = (
  companyId: number
): actionTypes.ResendActivation => ({
  type: c.RESEND_ACTIVATION,
  payload: {
    companyId,
  },
});

export const resendActivationSuccess = (
  payload: Company
): actionTypes.ResendActivationSuccess => ({
  type: c.RESEND_ACTIVATION_SUCCESS,
  payload,
});

export const duplicateCompanySuccess = (
  payload: Company
): actionTypes.DuplicateCompanySuccess => ({
  type: c.DUPLICATE_COMPANY_SUCCESS,
  payload,
});

export const resendActivationError = (
  payload: Error
): actionTypes.ResendActivationError => ({
  type: c.RESEND_ACTIVATION_ERROR,
  payload,
});

export const duplicateCompanyError = (
  payload: Error
): actionTypes.DuplicateCompanyError => ({
  type: c.DUPLICATE_COMPANY_ERROR,
  payload,
});

export const fetchCompaniesToSwitchSuccess = (
  payload: SwitchToCompanyData
): actionTypes.FetchCompaniesToSwitchSuccess => ({
  type: c.FETCH_COMPANIES_TO_SWITCH_SUCCESS,
  payload,
});

export const fetchCompaniesToSwitchError = (
  payload: Error
): actionTypes.FetchCompaniesToSwitchError => ({
  type: c.FETCH_COMPANIES_TO_SWITCH_ERROR,
  payload,
});

export const toggleCompanyActivated = ({
  id,
  churn,
}: {
  id: number;
  churn?: CompanyChurnData;
}): actionTypes.ToggleCompanyActivated => ({
  type: c.TOGGLE_COMPANY_ACTIVATED,
  payload: { id, churn },
});

export const toggleCompanyActivatedSuccess = ({
  id,
  is_active,
  churn,
}: {
  id: number;
  is_active: number;
  churn: CompanyChurnData;
}): actionTypes.ToggleCompanyActivatedSuccess => ({
  type: c.TOGGLE_COMPANY_ACTIVATED_SUCCESS,
  payload: { id, is_active, churn },
});

export const toggleCompanyActivatedError = (
  payload: Error
): actionTypes.ToggleCompanyActivatedError => ({
  type: c.TOGGLE_COMPANY_ACTIVATED_ERROR,
  payload,
});

export const fetchPlatforms = (
  companyId: number
): actionTypes.FetchPlatforms => ({
  type: c.FETCH_PLATFORMS,
  payload: {
    companyId,
  },
});

export const fetchPlatformsSuccess = (
  payload: Array<Platform>
): actionTypes.FetchPlatformsSuccess => ({
  type: c.FETCH_PLATFORMS_SUCCESS,
  payload,
});

export const fetchPlatformsError = (
  payload: Error
): actionTypes.FetchPlatformsError => ({
  type: c.FETCH_PLATFORMS_ERROR,
  payload,
});

export const createPlatform = (payload: {
  company_id: number;
  code: string;
  platform_value: string;
}): actionTypes.CreatePlatform => ({
  type: c.CREATE_PLATFORM,
  payload,
});

export const createPlatformSuccess = (
  payload: Platform
): actionTypes.CreatePlatformSuccess => ({
  type: c.CREATE_PLATFORM_SUCCESS,
  payload,
});

export const createPlatformError = (
  payload: Error
): actionTypes.CreatePlatformError => ({
  type: c.CREATE_PLATFORM_ERROR,
  payload,
});

export const deleteUserFromCompany = (payload: {
  companyId: number;
  userId: number;
}): actionTypes.DeleteUserFromCompany => ({
  type: c.DELETE_USER_FROM_COMPANY,
  payload,
});

export const deleteUserFromCompanySuccess = (payload: {
  statusCode: number;
  message: string;
  data: { userID: string; companyID: string; res: string };
}): actionTypes.DeleteUserFromCompanySuccess => ({
  type: c.DELETE_USER_FROM_COMPANY_SUCCESS,
  payload,
});

export const deleteUserFromCompanyError = (
  payload: Error
): actionTypes.DeleteUserFromCompanyError => ({
  type: c.DELETE_USER_FROM_COMPANY_ERROR,
  payload,
});

export const editPlatform = (payload: Platform): actionTypes.EditPlatform => ({
  type: c.EDIT_PLATFORM,
  payload,
});

export const editPlatformSuccess = (
  payload: Platform
): actionTypes.EditPlatformSuccess => ({
  type: c.EDIT_PLATFORM_SUCCESS,
  payload,
});

export const editPlatformError = (
  payload: Error
): actionTypes.EditPlatformError => ({
  type: c.EDIT_PLATFORM_ERROR,
  payload,
});

export const removePlatform = (
  platformId: number
): actionTypes.RemovePlatform => ({
  type: c.REMOVE_PLATFORM,
  payload: {
    platformId,
  },
});

export const removePlatformSuccess = (payload: {
  platformID: number;
}): actionTypes.RemovePlatformSuccess => ({
  type: c.REMOVE_PLATFORM_SUCCESS,
  payload,
});

export const removePlatformError = (
  payload: Error
): actionTypes.RemovePlatformError => ({
  type: c.REMOVE_PLATFORM_ERROR,
  payload,
});

export const populateHistoricalLeads = (
  payload: HistoricalLeadsData
): actionTypes.PopulateHistoricalLeads => ({
  type: c.POPULATE_HISTORICAL_LEADS,
  payload,
});

export const populateHistoricalLeadsSuccess = (
  payload: string
): actionTypes.PopulateHistoricalLeadsSuccess => ({
  type: c.POPULATE_HISTORICAL_LEADS_SUCCESS,
  payload,
});

export const populateHistoricalLeadsError = (
  payload: Error
): actionTypes.PopulateHistoricalLeadsError => ({
  type: c.POPULATE_HISTORICAL_LEADS_ERROR,
  payload,
});

export const getAiModels = (): actionTypes.GetAiModels => ({
  type: c.GET_AI_MODELS,
});

export const getAiModelsSuccess = (models: any) => ({
  type: c.GET_AI_MODELS_SUCCESS,
  payload: models,
});

export const getAiModelsError = (
  error: Error
): actionTypes.GetAiModelsError => ({
  type: c.GET_AI_MODELS_ERROR,
  payload: error,
});

export const getCompanyAIModels = (companyID: string) => ({
  type: c.GET_SELECTED_AI_MODELS,
  payload: companyID,
});

export const getCompanyAIModelsSuccess = (models: Array<string>) => ({
  type: c.GET_SELECTED_AI_MODELS_SUCCESS,
  payload: models,
});

export const getCompanyAIModelsError = (error: Error) => ({
  type: c.GET_SELECTED_AI_MODELS_ERROR,
  payload: error,
});

export const postCompanyAIModel = (companyID: string, model: string) => ({
  type: c.POST_SELECTED_AI_MODELS,
  payload: { companyID, model },
});

export const postCompanyAIModelSuccess = (model: string) => ({
  type: c.POST_SELECTED_AI_MODELS_SUCCESS,
  payload: model,
});

export const postCompanyAIModelError = (error: Error) => ({
  type: c.POST_SELECTED_AI_MODELS_ERROR,
  payload: error,
});

export const deleteCompanyAIModel = (companyID: string, model: string) => ({
  type: c.DELETE_SELECTED_AI_MODELS,
  payload: { companyID, model },
});

export const deleteCompanyAIModelSuccess = (model: string) => ({
  type: c.DELETE_SELECTED_AI_MODELS_SUCCESS,
  payload: model,
});

export const deleteCompanyAIModelError = (error: Error) => ({
  type: c.DELETE_SELECTED_AI_MODELS_ERROR,
  payload: error,
});

export const fetchCompanyCurrentLimits = (
  companyId: number
): actionTypes.GetCurrentLimits => ({
  type: c.GET_CURRENT_LIMITS,
  payload: { companyId },
});

export const getCurrentLimitsSuccess = (
  payload: Limits & { company_id: number }
): actionTypes.GetCurrentLimitsSuccess => ({
  type: c.GET_CURRENT_LIMITS_SUCCESS,
  payload,
});

export const getCurrentLimitsError = (
  payload: Error
): actionTypes.GetCurrentLimitsError => ({
  type: c.GET_CURRENT_LIMITS_ERROR,
  payload,
});

export const connectCompanies = (
  companyID: string,
  internalToken: string,
  sm_name: string,
  leadoo_name: string,
  onConnectionSuccess: () => void
): actionTypes.ConnectCompanies => ({
  type: c.CONNECT_COMPANIES,
  payload: {
    companyID,
    internalToken,
    sm_name,
    leadoo_name,
    onConnectionSuccess,
  },
});

export const getSMCompanyConnection = (
  companyID: string
): actionTypes.GetSMConnection => ({
  type: c.GET_SM_CONNECTION,
  payload: { companyID },
});

export const getSMCompanyConnectionSuccess = (
  companyID: string,
  smCompany: SalesManagoConnectedCompany | null
) => ({
  type: c.GET_SM_CONNECTION_SUCCESS,
  payload: { companyID, smCompany },
});

export const getSMCompanyByToken = (
  companyID: string,
  token: string,
  onSuccess: () => void
) => ({
  type: c.GET_SM_COMPANY_BY_TOKEN,
  payload: { companyID, token, onSuccess },
});

export const getSMCompanyByTokenSuccess = (
  internalToken: string,
  smCompanyInfo: SalesManagoCompanyInfo
) => ({
  type: c.GET_SM_COMPANY_BY_TOKEN_SUCCESS,
  payload: { internalToken, smCompanyInfo },
});

export const deleteSmConnection = (
  companyID: string,
  name: string,
  onDisconnect: () => void
) => ({
  type: c.DELETE_SM_CONNECTION,
  payload: { companyID, name, onDisconnect },
});

export const getSMByTokenError = (error: string) => ({
  type: c.GET_SM_COMPANY_BY_TOKEN_ERROR,
  payload: error,
});

export const clearSmErrors = () => ({
  type: c.CLEAR_ERRORS,
});
