export enum AuthActionTypes {
  SIGN_IN = "[AUTH_MODULE] SIGN_IN",
  SIGN_IN_SUCCESS = "[AUTH_MODULE] SIGN_IN_SUCCESS",
  SIGN_IN_ERROR = "[AUTH_MODULE] SIGN_IN_ERROR",

  SIGN_OUT = "[AUTH_MODULE] SIGN_OUT",
  SIGN_OUT_SUCCESS = "[AUTH_MODULE] SIGN_OUT_SUCCESS",
  SIGN_OUT_ERROR = "[AUTH_MODULE] SIGN_OUT_ERROR",

  FETCH_ME_USER = "[AUTH_MODULE] FETCH_ME_USER",
  FETCH_ME_USER_SUCCESS = "[AUTH_MODULE] FETCH_ME_USER_SUCCESS",
  FETCH_ME_USER_ERROR = "[AUTH_MODULE] FETCH_ME_USER_ERROR",

  FETCH_ME_OAUTH = "[AUTH_MODULE] FETCH_ME_OAUTH",
  FETCH_ME_OAUTH_SUCCESS = "[AUTH_MODULE] FETCH_ME_OAUTH_SUCCESS",
  FETCH_ME_OAUTH_ERROR = "[AUTH_MODULE] FETCH_ME_OAUTH_ERROR",

  FETCH_USER_BY_CODE = "[AUTH_MODULE] FETCH_USER_BY_CODE",
  FETCH_USER_BY_CODE_SUCCESS = "[AUTH_MODULE] FETCH_USER_BY_CODE_SUCCESS",
  FETCH_USER_BY_CODE_ERROR = "[AUTH_MODULE] FETCH_USER_BY_CODE_ERROR",
}
