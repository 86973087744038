export const colors = {
  white: "#fff",
  black: "#000",
  dark: "rgb(66, 66, 66)",
  pink: "#d7598b",
  gray: "#9e9e9e",
  red: "#f00",
};

export const adminApiUrl = process.env.REACT_APP_ADMIN_API_URL;
export const freeUsersApiUrl = process.env.REACT_APP_FREE_USERS_API_URL;
export const casApiUrl = process.env.REACT_APP_CAS_API_URL;

export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const LEADGEN_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm";
export const OPTIONAL_PHONE_REG_EXP = /^$|^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
export const DOMAIN_REGEX = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;