import { AuthActionTypes } from "./actions";

import { OAuth, User } from "libs/models";
import * as actionTypes from "./actionTypes";

export const signOut = (): actionTypes.SignOut => ({
  type: AuthActionTypes.SIGN_OUT,
});

export const signOutSuccess = (): actionTypes.SignOutSuccess => ({
  type: AuthActionTypes.SIGN_OUT_SUCCESS,
});

export const signOutError = (payload: Array<string>): actionTypes.SignOutError => ({
  type: AuthActionTypes.SIGN_OUT_ERROR,
  payload,
});

export const fetchMeUser = (): actionTypes.FetchMeUser => ({
  type: AuthActionTypes.FETCH_ME_USER,
});

export const fetchMeUserSuccess = (payload: User): actionTypes.FetchMeUserSuccess => ({
  type: AuthActionTypes.FETCH_ME_USER_SUCCESS,
  payload,
});

export const fetchMeUserError = (payload: Array<string>): actionTypes.FetchMeUserError => ({
  type: AuthActionTypes.FETCH_ME_USER_ERROR,
  payload,
});

export const fetchMeOAuth = (): actionTypes.FetchMeOAuth => ({
  type: AuthActionTypes.FETCH_ME_OAUTH,
});

export const fetchMeOAuthSuccess = (payload: OAuth): actionTypes.FetchMeOAuthSuccess => ({
  type: AuthActionTypes.FETCH_ME_OAUTH_SUCCESS,
  payload,
});

export const fetchMeOAuthError = (payload: Array<string>): actionTypes.FetchMeOAuthError => ({
  type: AuthActionTypes.FETCH_ME_OAUTH_ERROR,
  payload,
});

export const fetchUserByCode = (payload: { code: string; pkceVerifier: string | null }): actionTypes.FetchUserByCode => ({
  type: AuthActionTypes.FETCH_USER_BY_CODE,
  payload,
});

export const fetchUserByCodeSuccess = (payload: { jwt: string; user: User }): actionTypes.FetchUserByCodeSuccess => ({
  type: AuthActionTypes.FETCH_USER_BY_CODE_SUCCESS,
  payload,
});

export const fetchUserByCodeError = (payload: Array<string>): actionTypes.FetchUserByCodeError => ({
  type: AuthActionTypes.FETCH_USER_BY_CODE_ERROR,
  payload,
});
