import { AxiosResponse } from "axios";

import { BaseService } from "libs/services";
import {FetchUsersQueryParams} from "../../../../../shared/types/requests";

export class UserManagementService extends BaseService {
  private ADMIN_API_ROUTE = this.ADMIN_API_HOST;

  static _instance: UserManagementService;

  constructor() {
    super();

    if (UserManagementService._instance) {
      throw Error("Unable to create an instance!");
    }
  }

  static getInstance(): UserManagementService {
    if (!UserManagementService._instance) {
      UserManagementService._instance = new UserManagementService();
    }

    return UserManagementService._instance;
  }

  public fetchUsers = (params?: FetchUsersQueryParams): Promise<AxiosResponse> => this.get(`${this.ADMIN_API_ROUTE}/users`, { params });

  public changeAdmin = (userId: number): Promise<AxiosResponse> => this.put(`${this.ADMIN_API_ROUTE}/users/changeAdmin/${userId}`);

  public fetchCompaniesToAssign = (userId: number): Promise<AxiosResponse> => this.get(`${this.ADMIN_API_ROUTE}/users/assignCompany/${userId}`);

  public assignCompanyToUser = (data: { userId: number; companies: Array<number> }): Promise<AxiosResponse> => this.put(`${this.ADMIN_API_ROUTE}/users/assignCompany/${data.userId}`, { companies: data.companies });

  public deassignCompanyToUser = (data: { userId: number; companyId: number }): Promise<AxiosResponse> => this.delete(`${this.ADMIN_API_ROUTE}/users/assignCompany/user/${data.userId}/company/${data.companyId}`);

  public offboardingUser = (email: string): Promise<AxiosResponse> => this.delete(`${this.ADMIN_API_ROUTE}/p/cs/user/deactivate/${email}/email`);
}
